// Packages
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import PageHeader from "~components/sections/PageHeader";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import SubTitle from "~components/typography/SubTitle";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Ul from "~components/typography/Ul";
import InvestInYourSelf from "~components/sections/InvestInYourSelf";
import Blockquote from "~components/typography/Blockquote";
import H3 from "~components/typography/H3";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	profileImage: IGatsbyImageData;
	infografik: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const profileImage = getImage(data.profileImage);
	const infografik = getImage(data.infografik);

	return (
		<Layout
			location={location}
			title="Profil"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Profil"
				title="Dein Coach Daniel Thiel"
				description={
					<div>
						{[
							"Freiheit",
							"Unabhängigkeit",
							"Lieben & Leben",
							"Werte",
							"1982",
							"Wirtschaftswissenschaften",
							"Ausbildung ",
							"DAX notiertes Unternehmen",
							"Investment",
							"Essen & Kochen",
							"Kulinarisch",
							"Reisen & Besichtigen",
							"Lesen",
							"Wellness & Erholung",
							"Sport",
							"Snowboard",
							"Windsurfen",
							"USA",
							"Kassel",
							"Humor",
							"Aktien & Fonds",
							"Familie & Freunde",
							"Vertrauen",
							"Spaß & Lachen",
							"Wissen & Lernen",
							"Wein & Cocktails",
							"Workshops & Coaching ",
							"Keynote Speaker",
							"Persönlichkeitsentwicklung",
							"Finanzen",
							"Immobilien",
							"Entspannung",
							"Empathie",
							"Kreativität",
							"Wachstum",
							"Partizipation",
							"Umsetzung & Verwirklichung",
							"Zukunftsorientiert",
							"Ziele",
							"Kreuzfahrten",
							"Kunst",
							"Infotainment",
							"Joggen",
							"Moderieren",
							"Freizeit",
							"Elvis Presley",
							"Musik",
							"Mentoring",
						].map((keyword) => (
							<span className="m-2 inline-flex items-center text-xs font-medium capitalize text-gray-400" key={keyword}>
								{keyword}
							</span>
						))}
					</div>
				}
			/>
			<Container>
				<Grid cols={2}>
					<div>
						<SubTitle className="mb-2">Profil</SubTitle>

						<H2 className="mb-4">Steckbrief</H2>
						<Ul
							items={[
								"Wirtschaftswissenschaftler",
								"Systemischer Business Coach",
								"Hypnose Coach",
								"Firmentrainer",
								"Keynote-Speaker",
							]}
						/>
					</div>
					<div>
						{profileImage ? (
							<GatsbyImage
								image={profileImage}
								alt="Daniel Tiel Profilbild"
								title="Daniel Thiel Profilbild"
								className="rounded"
							/>
						) : null}
					</div>
				</Grid>
			</Container>
			<Container>
				<H2 className="mb-4">Profil über das Thema Warum</H2>
				<Grid cols={2}>
					<div className="prose">
						<p>
							Daniel Thiel ist Wirtschafts&shy;wissenschaftler, Finanzexperte, Business Coach, Firmentrainer, Hypnose
							Coach, Autor und Keynote-Speaker. Nach &uuml;ber zehn Jahren Erfahrung als F&uuml;hrungskraft im
							Investmentbereich begleitet er heute Menschen, die unter Druck Spitzenleistungen bringen m&uuml;ssen
							&ndash; vom Eishockeyspieler bis zur F&uuml;hrungspers&ouml;nlichkeit.
						</p>
						<p>
							Sein Antrieb ist es, Wissen weiterzugeben, das Menschen dabei unterst&uuml;tzt, &uuml;ber sich
							hinauszuwachsen &ndash; im Sport, im Business und im Leben. Er selbst sieht sich als &bdquo;ewig
							Lernenden&ldquo; und hat seine eigene Entwicklung konsequent vorangetrieben. Zu seinen Stationen
							geh&ouml;ren unter anderem die Greator GmbH als Keynote-Speaker, &bdquo;Mehr Gesch&auml;ft GmbH&ldquo; als
							Business Coach, Speaker- &amp; Trainer-Camp bei Oliver Geisselhart sowie zahlreiche Weiterbildungen im
							Bereich Hypnose und Mentaltraining.
						</p>
						<p>
							Neben seiner Arbeit als Coach ist er auch als Firmentrainer im Bereich Speed Reading aktiv. Gemeinsam mit
							seinem Team hilft er Unternehmen deutschlandweit, im Zeitalter von Digitalisierung und KI effizienter zu
							lernen und zu denken &ndash; durch schnellere Informationsverarbeitung f&uuml;r Mitarbeiter und
							F&uuml;hrungskr&auml;fte.
						</p>
						<p>
							Daniel Thiel lebt mit seiner Frau und seinem Sohn in Kassel. Er liebt es, sich weiterzuentwickeln, Wissen
							zu teilen und Menschen mental zu st&auml;rken &ndash; f&uuml;r mehr Klarheit, Fokus und echte
							Ver&auml;nderung.
						</p>
						<p>
							&bdquo;Lange Zeit definierte ich mich &uuml;ber Leistung, Verantwortung, &auml;u&szlig;eren und
							monet&auml;ren Erfolg. Als F&uuml;hrungskraft im Investmentbereich stand ich t&auml;glich unter extremen
							Druck &ndash; getrieben vom Wunsch nach Anerkennung und Lob, st&auml;ndig auf der Jagd nach dem
							n&auml;chsten Ergebnis und Erfolg. Ich funktionierte, war leistungsgetrieben, und obwohl ich auf dem
							Papier erfolgreich war, f&uuml;hlte ich mich innerlich leer, frustriert und ungl&uuml;cklich.
						</p>
					</div>
					<div className="prose">
						<p>
							Erst als ich meine Frau kennenlernte und einen neuen Weg einschlug, &auml;nderte sich das wieder. Ich
							verlie&szlig; das Unternehmen und startete mein eigenes Business als Speaker, Business Coach und
							Firmentrainer. Wurde entspannter, zufriedener, ges&uuml;nder &ndash; und vor allem wieder gl&uuml;cklich.
						</p>
						<p>
							W&auml;hrend meiner Zeit als F&uuml;hrungskraft habe ich selbst viel Coaching erhalten und dabei gemerkt,
							wie sehr mich diese Arbeit weiterbringt - nicht nur beruflich, sondern auch pers&ouml;nlich. Ich bin daran
							gewachsen. Schlie&szlig;lich lie&szlig; ich mich selbst als Business Coach ausbilden, um meine Mitarbeiter
							gezielter zu f&ouml;rdern und erfolgreicher zu machen.
						</p>
						<p>
							Im Laufe dieser Reise habe ich viele Stationen durchlaufen und mein fundiertes Wissen sowie tiefgehende
							Erfahrungen in den Bereichen Business Coaching, Leadership, Hypnose und Pers&ouml;nlichkeitsentwicklung
							vertieft und erfolgreich mit meinen Kunden umgesetzt. Dieses Wissen habe ich im Buch &bdquo;So entfalten
							Eishockeyspieler ihr volles Potenzial&ldquo; kompakt, praxisnah und zielgerichtet zusammengefasst.
						</p>
						<p>
							Mit diesem Buch m&ouml;chte ich all jene erreichen, die sich nach dem n&auml;chsten Level sehnen &ndash;
							im Sport oder im Business. Menschen, die sp&uuml;ren, dass in ihnen mehr steckt. Und die bereit sind,
							diesen Weg zu gehen.
						</p>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2}>
					<div>
						{infografik ? (
							<GatsbyImage image={infografik} alt="Mein Was, Wie, Warum" title="Mein Was, Wie, Warum" />
						) : null}
					</div>
					<div>
						<SubTitle className="mb-2">Meine Motivation</SubTitle>
						<H2 className="mb-10">Wie ich mich selbst motiviere</H2>
						<H3 className="mb-4">Mein Warum</H3>
						<Blockquote
							text="Bei allem was ich mache, glaube ich daran Menschen besser zu machen und meinen Teil dazu beizutragen, dass Menschen ihr eigenes Potential erkennen."
							size="normal"
							align="left"
							className="mb-4"
						/>
						<P className="mb-3">
							Ich hatte in meiner Kindheit und in meinem bisherigen Leben sehr viel Liebe und Geborgenheit von meiner
							Familie erfahren. Dafür bin ich unfassbar dankbar.
						</P>
						<P className="mb-3">Diese Liebe erfahren zu dürfen, machte mich stark um für Menschen einzustehen. </P>
						<P className="mb-3">
							Menschen die nicht dieses Glück hatten, möchte ich etwas zurück zugeben. Sie zu inspirieren und sie besser
							zu machen, damit auch sie über sich hinauswachsen und ihr eigenes Potential erkennen, beseelt mich und
							treibt mich an.
						</P>
						<P className="mb-10">Wer ein Warum hat, dem ist kein Wie zu schwer.</P>
						<H3 className="mb-4">Mein Wie</H3>
						<P className="mb-10">
							Damit meine Kunden ihre eigenen Kompetenzen und Potentiale entwickeln, berate ich strategisch, veranstalte
							Workshops, bilde Mitarbeiter und Führungskräfte in Methoden und Mindsets aus, coache Teams oder packe auch
							selbst mit an wenn es um den Aufbau digitaler Units, neuer Produkte und Geschäftsmodelle geht. Das heißt,
							ich mache alles was meinen Kunden hilft ihre eigenen Kompetenzen zu entwickeln und ihrem eigenen Warum
							eine Definition zu geben. Damit fülle ich die Lücken, die der eigenen “Sinnerfüllung” meiner Kunden,
							dienlich sind. Das mache ich solange, bis die Hilfe zur eigenen Potentialentfaltung, Früchte trägt und ich
							meine Kunden ruhigen Gewissens “alleine laufen lassen” kann.
						</P>
						<H3 className="mb-4">Mein Was</H3>
						<P className="mb-3">
							Ich veranstalte Workshops, berate und coache, damit das Potential meiner Teilnehmer gefördert wird und ans
							Tageslicht kommt.
						</P>
						<P className="mb-3">
							Das eigene Potential in sich selbst zu erkennen und das eigene Warum zu kennen, ist oft der Schlüssel für
							die Erreichung der eigenen Ziele.
						</P>
						<P className="mb-3">
							Dafür benötigt man die richtigen Tools. Meine Teilnehmer wollen über sich hinauswachsen und sind bereit in
							sich selbst zu investieren, damit man für die wirklich wichtigen Dinge im Leben Zeit hat.{" "}
						</P>
						<P className="mb-3">Möchtest Du ein Teil unserer Community werden?</P>
						<P>Finde Deinen Weg und investiere in Dich selbst.</P>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2}>
					<div>
						{infografik ? (
							<GatsbyImage image={infografik} alt="Mein Was, Wie, Warum" title="Mein Was, Wie, Warum" />
						) : null}
					</div>
					<div>
						<SubTitle className="mb-2">Meine Motivation</SubTitle>
						<H2 className="mb-10">Wie ich mich selbst motiviere</H2>
						<H3 className="mb-4">Mein Warum</H3>
						<Blockquote
							text="Bei allem was ich mache, glaube ich daran Menschen besser zu machen und meinen Teil dazu beizutragen, dass Menschen ihr eigenes Potential erkennen."
							size="normal"
							align="left"
							className="mb-4"
						/>
						<P className="mb-3">
							Ich hatte in meiner Kindheit und in meinem bisherigen Leben sehr viel Liebe und Geborgenheit von meiner
							Familie erfahren. Dafür bin ich unfassbar dankbar.
						</P>
						<P className="mb-3">Diese Liebe erfahren zu dürfen, machte mich stark um für Menschen einzustehen. </P>
						<P className="mb-3">
							Menschen die nicht dieses Glück hatten, möchte ich etwas zurück zugeben. Sie zu inspirieren und sie besser
							zu machen, damit auch sie über sich hinauswachsen und ihr eigenes Potential erkennen, beseelt mich und
							treibt mich an.
						</P>
						<P className="mb-10">Wer ein Warum hat, dem ist kein Wie zu schwer.</P>
						<H3 className="mb-4">Mein Wie</H3>
						<P className="mb-10">
							Damit meine Kunden ihre eigenen Kompetenzen und Potentiale entwickeln, berate ich strategisch, veranstalte
							Workshops, bilde Mitarbeiter und Führungskräfte in Methoden und Mindsets aus, coache Teams oder packe auch
							selbst mit an wenn es um den Aufbau digitaler Units, neuer Produkte und Geschäftsmodelle geht. Das heißt,
							ich mache alles was meinen Kunden hilft ihre eigenen Kompetenzen zu entwickeln und ihrem eigenen Warum
							eine Definition zu geben. Damit fülle ich die Lücken, die der eigenen “Sinnerfüllung” meiner Kunden,
							dienlich sind. Das mache ich solange, bis die Hilfe zur eigenen Potentialentfaltung, Früchte trägt und ich
							meine Kunden ruhigen Gewissens “alleine laufen lassen” kann.
						</P>
						<H3 className="mb-4">Mein Was</H3>
						<P className="mb-3">
							Ich veranstalte Workshops, berate und coache, damit das Potential meiner Teilnehmer gefördert wird und ans
							Tageslicht kommt.
						</P>
						<P className="mb-3">
							Das eigene Potential in sich selbst zu erkennen und das eigene Warum zu kennen, ist oft der Schlüssel für
							die Erreichung der eigenen Ziele.
						</P>
						<P className="mb-3">
							Dafür benötigt man die richtigen Tools. Meine Teilnehmer wollen über sich hinauswachsen und sind bereit in
							sich selbst zu investieren, damit man für die wirklich wichtigen Dinge im Leben Zeit hat.{" "}
						</P>
						<P className="mb-3">Möchtest Du ein Teil unserer Community werden?</P>
						<P>Finde Deinen Weg und investiere in Dich selbst.</P>
					</div>
				</Grid>
			</Container>
			<InvestInYourSelf />
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		profileImage: file(relativePath: { eq: "pages/profil/profilbild_daniel-thiel.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
		infografik: file(relativePath: { eq: "pages/profil/was-wie-warum.png" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
	}
`;
